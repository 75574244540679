import HaciendaItem from "./HaciendaItem";
import {HaciendaLote} from "./HaciendaLote";
import HaciendaBase from "./HaciendaBase";
import {Save,Obtener,Editar} from '@/Generico/Conexion';
import ResponseCustom from "../ResponseCustom";
import {mainAxios,axiosKtb} from '@/plugins/axios';
import ResponseGenerico from "../ResponseGenerico";


export default class HaciendaItemLote extends HaciendaBase
{
    id:number |undefined=0;
    haciendaLoteId : number |undefined = 0;
    hectareas:number|undefined = 0;
    estado:boolean |undefined = false;
    estadoLote?:string="";
    haciendaItemId : number |undefined =0;
    descripcion?:string="";
    ordenInterna?: string="";
    latitud:number | undefined =0;
    longitud:number |undefined=0;
    haciendaItem:HaciendaItem | undefined;
    haciendaName?: string="";
    haciendaId:number =0;
    usuarioId?: string="";

    constructor(_loteId?:number,_descripcion?:string,_haciendaLoteId?:number,_ordenInterna?:string,_haciendaName?:string,_estadoLote?:string,_latitud?:number,_longitud?:number,_areaTotal?:number,_areaPlantada?:number,_areaDisponible?:number,_areaProteccionHidrica?:number,
        _areaReservaForestal?:number,_areaLineaElectrica?:number,_areaOleoducto?:number,_areaCampanento?:number,_areaCaminos?:number,_areaSubsolada?:number,_usuarioId?:string
        )
    {
        super(_areaTotal,_areaPlantada,_areaDisponible,_areaProteccionHidrica,
            _areaReservaForestal,_areaLineaElectrica,_areaOleoducto,_areaCampanento,_areaCaminos,_areaSubsolada);
        this.descripcion = _descripcion;
        this.haciendaName = _haciendaName;
        this.estadoLote = _estadoLote;
        this.latitud = _latitud;
        this.longitud = _longitud;
        this.usuarioId = _usuarioId;
    }

    static async GuardarHaciendaItemLote(HaciendaItemLote:HaciendaItemLote): Promise<ResponseGenerico>
    {
        return Save<HaciendaItemLote>('LoteItem/agregarlote',HaciendaItemLote,mainAxios);
    }

    static async EditarEstado(HaciendaItemLote:HaciendaItemLote):Promise<ResponseGenerico>
    {
        return await Editar("LoteItem/CambiarEstado",HaciendaItemLote,mainAxios);
    }

    static async EditarHaciendaItemLote(HaciendaItemLote:HaciendaItemLote): Promise<ResponseGenerico>
    {
        return Editar<HaciendaItemLote>('LoteItem/editarlote',HaciendaItemLote,mainAxios);
    }

    static async ObtenerHaciendaItemLote(): Promise<ResponseCustom<HaciendaItemLote>>
    {
        return Obtener<HaciendaItemLote>('LoteItem/ObtenerLoteItemAll',mainAxios);
    }
    static async ObtenerHaciendaRecepction(plantaId:number,tipoId:number)
    {
        return Obtener<HaciendaItemLote>(`reporte/obtenerhaciendarecepcion/${plantaId}/${tipoId}`,axiosKtb);
    }
}
