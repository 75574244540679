






































import Component from "vue-class-component";
import Hacienda from "../entidades/Plantacion/Hacienda";
import Vue from "vue";
import Tabla from "../components/General/TablaGeneralFinal.vue";
import Agregar from "../components/Maestro/Hacienda/Agregar.vue";
import AgregarAnioCultivo from "@/components/Maestro/Hacienda/AgregarItem.vue";
import Formulario from "../components/Formulario.vue";
import HaciendaItemLote from "@/entidades/Plantacion/HaciendaItemLote";
import HaciendaItem from "@/entidades/Plantacion/HaciendaItem";
import { Mutation } from "vuex-class";
import Loading from "@/entidades/Sistema/Loading";
import Alerta from "@/entidades/Sistema/Alerta";
import ResponseGenerico from "@/entidades/ResponseGenerico";

@Component({
  components: {
    Tabla,
    Formulario,
    Agregar,
    AgregarAnioCultivo,
    AgregarLote: () =>
      import("@/components/Maestro/Hacienda/AgregarLoteHacienda.vue"),
  },
})
export default class extends Vue {
  //Propiedades
  @Mutation("mostrarLoading", { namespace: "sistemaModule" })
  mostrarLoading!: Function;
  @Mutation("mostarAlert", { namespace: "sistemaModule" })
  mostarAlert!: Function;

  message?: String = "";
  notificacion?: Boolean = false;
  haciendas: Array<HaciendaItem> = [];
  listado?: Array<HaciendaItemLote> = [];
  header: { text: string; sortable: boolean; value: string }[] = [
    { text: "Hacienda", sortable: true, value: "haciendaName" },
    { text: "Descripcion", sortable: true, value: "descripcion" },
    { text: "Orden Interna", sortable: true, value: "ordenInterna" },
    { text: "A. Total", sortable: false, value: "areaTotal" },
    { text: "A. Plantada", sortable: false, value: "areaPlantada" },
    { text: "A. Disponible", sortable: false, value: "areaDisponible" },
    {
      text: "A. Reserva Forestal",
      sortable: false,
      value: "zonaReservaForestal",
    },
    {
      text: "A. Reserva Hidrica",
      sortable: false,
      value: "zonaProteccionHidrica",
    },
    { text: "A. Caminos", sortable: false, value: "areaCaminos" },
    {
      text: "A. Linea Electrica",
      sortable: false,
      value: "areaLineaElectrica",
    },
    { text: "A. Oleoducto", sortable: false, value: "areaOleoducto" },
    { text: "A. Campamentos", sortable: false, value: "areaCampamento" },
    { text: "A. Subsolada", sortable: false, value: "areaSubsolada" },
    { text: "Acciones", value: "actions", sortable: false },
    { text: "Estado", sortable: false, value: "estado" },
  ];
  hacienda: Hacienda | undefined = new Hacienda();
  HaciendaItemLote: HaciendaItemLote | undefined = new HaciendaItemLote();
  haciendaItems: Array<HaciendaItem> = [];
  haciendaItem: HaciendaItem | undefined = new HaciendaItem();

  //releacionado al agregar el modo hacienda item
  modalHaciendaItemLote: boolean = false;
  modalHaciendaItemLoteName: string = "";
  //Agregar una hacienda
  modalHaciendaName: string = "";
  modalHacienda: boolean = false;
  //todos los metods

  async Obtener() {
    try {
      this.mostrarLoading(new Loading(true, "Obteniendo Registros"));
      var rsp = await HaciendaItemLote.ObtenerHaciendaItemLote();
      if (rsp.isSuccess) {
        this.listado = rsp.isResult;
        console.log(this.listado);
      } else {
        this.notificacion = true;
        this.message = rsp.isMessage;
      }
    } catch (error) {
      //@ts-ignore
      this.mostarAlert(new Alerta(error, true, "danger", 2000));
    } finally {
      this.mostrarLoading(new Loading(false, ""));
    }
  }

  async agregarHaciendaItemLote(haciendaItemLote: HaciendaItemLote) {
    try {
      this.mostrarLoading(new Loading(true, "Agregando"));
      let response = new ResponseGenerico(false, "");
      response =
        this.HaciendaItemLote != undefined
          ? await HaciendaItemLote.GuardarHaciendaItemLote(haciendaItemLote)
          : await HaciendaItemLote.EditarHaciendaItemLote(haciendaItemLote);
      if (response.isSuccess == true) {
        this.mostarAlert(new Alerta(response.isMessage, true, "primary", 2000));
        this.modalHaciendaItemLote = false;
        this.modalHaciendaItemLoteName = "";
        this.hacienda = undefined;
      } else {
        this.mostarAlert(new Alerta(response.isMessage, true, "danger", 2000));
      }
    } catch (error) {
      //@ts-ignore
      this.mostarAlert(new Alerta(error, true, "danger", 2000));
    } finally {
      this.mostrarLoading(new Loading(false, ""));
    }
  }

  async updateEstadoDb(haciendaItemLote: any) {
    try {
      this.mostrarLoading(new Loading(true, "Actualizando estado"));
      haciendaItemLote.id=haciendaItemLote.haciendaLoteId;
      var response = await HaciendaItemLote.EditarEstado(haciendaItemLote);
      if (response.isSuccess == true) {
        this.mostarAlert(new Alerta(response.isMessage, true, "primary", 2000));
        await this.Obtener();
      } else {
        this.mostarAlert(new Alerta(response.isMessage, true, "danger", 2000));
      }
    } catch (error) {
      //@ts-ignore
      this.mostarAlert(new Alerta(error, true, "danger", 2000));
    } finally {
      this.mostrarLoading(new Loading(false, ""));
    }
  }

  async abrirModalLote() {
    try {
      this.haciendaItems = (await HaciendaItem.ObtenerHaciendaItem()).isResult;
      // console.log(this.haciendaItems)
      //this.haciendaItem = undefined;
      this.modalHaciendaItemLoteName = "AgregarLote";
      this.modalHaciendaItemLote = true;
    } catch (error) {
      console.log(error);
    }
  }

  async editarLoteModal(HaciendaItemLote: HaciendaItemLote) {
    console.log("entramos");
    try {
      this.haciendaItems = (await HaciendaItem.ObtenerHaciendaItem()).isResult;
      this.HaciendaItemLote = HaciendaItemLote;
      this.HaciendaItemLote.id = HaciendaItemLote.haciendaLoteId;
      // this.haciendaItem = undefined;
      this.modalHaciendaItemLoteName = "AgregarLote";
      this.modalHaciendaItemLote = true;
    } catch (error) {
      console.log(error);
    }
  }


  closeModalHaciendaItemLote() {
    console.log("llegamos");
    this.modalHaciendaItemLote = false;
    this.modalHaciendaItemLoteName = "";
    this.hacienda = undefined;
    this.HaciendaItemLote = new HaciendaItemLote();
    this.Obtener();
  }
  //ciclo de vida
  mounted() {
    this.Obtener();
  }
}
